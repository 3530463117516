import { defineStore } from 'pinia';
import ProductSolutions from '~/json/products.solutions.json';
import ProductGallery from '~/json/product.gallery.json';
import ProductsRelated from '~/json/products.related.json';
import RelatedModels from '~/json/products.model.json';
import { usePerformanceStore } from './PerformanceStore';

export const useProductStore = defineStore('product', {
  state() {
    return {
      galleryData: null,
      currentGallery: null,
      platesData: null,
      currentPlates: null,
      modelsData: null,
      currentModels: null,
      productsData: null,
      currentRelatedProducts: null,
      resourcesData: null,
      currentResourcesData: null,
      familyResourcesData: null,
      currentFamilyResourcesData: null,
      familyProductsData: null,
      currentFamilyProductData: null,
      productID: null,
      productSolutionsData: null,
      currentProductSolutions: null,
      plateCrops: null,
      terms: [],
      productNav: null,
      storyData: null,
      productFam: null,
      productFamilies: null,
      currentStoryData: null,
      productRelatedData: null,
      dataLoaded: false,
      familyDataLoaded: false,
      // should we create a CropsStore
      cropsDataLoaded: false,
      crops: null,
      productCrops: null,
      products: null,
      resources: null,
    };
  },
  actions: {
    getGalleryData(pID) {
      const filterGalleryItems = ProductGallery.filter(
        (element) =>
          element.pID_fk === pID && element.pgFilename.includes('gallery')
      );
      if (filterGalleryItems.length === 0) {
        this.currentGallery = null;
      } else {
        this.currentGallery = filterGalleryItems;
      }
      return filterGalleryItems;

    },
    getPlateData(pID) {

      const platesData = this.platesData.filter(
        (element) => element.pID_fk === parseInt(pID)
      );

      const performanceStore = usePerformanceStore();
      platesData.forEach((element) => {
        element.pages = performanceStore.plateCrops(element.pID_fk, this.plateCrops);
        performanceStore
          .getAllPerformanceData(element.pID_fk)
          .then((result) => {
            element.perfData = result;
          });
      });

      this.currentPlates = platesData;

      console.log(this.currentPlates, ' current plates');

    },

    getModelData(pID, productSpecs) {

      let relatedModels = RelatedModels.filter(
        (element) => {
          return element.pID_fk === parseInt(pID)
        }
      );
      let models = [];

      //retrieve product info for each related model
      relatedModels.forEach((mod) => {
        var rMod = productSpecs.find((pd) => pd.pID === mod.modelID && pd.isModel);
        if (rMod)
          models.push(rMod);
      })
      models.sort((a, b) => a.productSortorder - b.productSortorder);
      this.currentModels = models;
      return models;
    },
    getProductRelatedData(product, allProducts, allFamilies) {
      const performanceStore = usePerformanceStore();
      //pull related products from master list
      var relatedProducts = ProductsRelated.filter(
        (element) => element.pID_fk === parseInt(product.pID)
      );

      //look up the related product from the products list
      const formatProducts = allProducts.filter((element) => {
        let relatedProduct = relatedProducts.find((item) => item.relatedProductID_fk === parseInt(element.pID));
        return relatedProduct;
      });
      
      const formatProductData = formatProducts.map((element) => {
        var family = allFamilies.find((fam)=>{ return fam.pfID === element.pfID_fk });
        element.productFullUrl =
          '/products/' +
          family.productFamilyURL +
          '/' +
          (element.productURL);
        performanceStore.getAllPerformanceData(element.pID).then((response) => {
          element.perfData = response;
        });
        element.resourceUri =
          '/images/products/' +
          (element.productURL) +
          '-hero.webp';

        return element;
      });
      formatProductData.sort((a, b) => a.productSortorder - b.productSortorder);
      if (formatProductData.length === 0) {
        this.currentRelatedProducts = null;
      } else {
        this.currentRelatedProducts = formatProductData;
      }
      return this.currentRelatedProducts;
      //console.log(this.currentRelatedProducts, ' related');

    },
    getProductSolutionsData(pID_fk) {
      const productSolutions = ProductSolutions.filter(
        (element) => element.pID_fk === pID_fk
      );
      const formatSolutions = this.productSolutionsData.filter((element) => {
        let relatedSolutions = null;
        productSolutions.forEach((item) => {
          if (item.solID_fk == element.solID_fk) {
            relatedSolutions = item;
          }
        });
        return relatedSolutions;
      });
      if (formatSolutions.length === 0) {
        this.currentProductSolutions = null;
      } else {
        this.currentProductSolutions = formatSolutions;
      }
    },
    // TODO this function isn't used
    getProductResources(pID) {
      this.currentResourcesData = [];
      this.currentResourcesData = this.resourcesData.filter(
        (element) => element.cspID_fk === pID
      );
      this.currentResourcesData.forEach((item) => {

        //console.log(item);

        if (item.videoURL != null && item.videoURL != 'NULL') {
          item.filePath = item.videoURL;
          item.type = 'Video';
        } else {
          const fileReg = item.filename.match(/\.([0-9a-z]+)(?:[#]|$)/i);
          item.filePath =
            '/library/' + item.filename;
          item.type = fileReg[1];
        }

      });
      const categories = (arr, property) => {
        return arr.reduce((acc, current) => {
          acc[current[property]] = [...(acc[current[property]] || []), current];
          return acc;
        }, {});
      };
      const items = categories(this.currentResourcesData, 'libraryCategory');
      const keys = Object.keys(items);
      const values = Object.values(items);
      var formatResourcesData = keys.map((element, index) => {
        return {
          name: element,
          items: values[index],
        };
      });
      this.currentResourcesData = formatResourcesData;
      if (this.currentResourcesData.length === 0) {
        this.currentResourcesData = null;
      }


    },

    getProductFamilyResources(pID, title, rt) {


      this.currentFamilyResourcesData = this.familyResourcesData.filter(
        (element) => element.cspID_fk === pID
      );


      this.currentFamilyResourcesData.forEach((item) => {

        if (item.videoURL != null && item.videoURL != 'NULL') {
          item.filePath = rt(item.videoURL);
          item.type = 'Video';
        } else {
          const fileReg = rt(item.filename).match(/\.([0-9a-z]+)(?:[#]|$)/i);
          item.filePath =
            '/library/' + rt(item.filename);
          item.type = fileReg[1].toUpperCase();
        }

      });
      this.currentFamilyResourcesData = {
        title: title,
        resources: this.currentFamilyResourcesData,
      };

      /*      if (this.currentFamilyResourcesData.length === 0) {
              this.currentFamilyResourcesData = null;
            }*/

    },
    getFamilyProducts(title) {
      const performanceStore = usePerformanceStore();
      const items = this.familyProductsData.map((element) => {
        const prodFamily = this.prodFamilies.filter(
          (item) => item.pfID_fk === element.pfID_fk
        );
        element.productFullUrl =
          '/products/' +
          prodFamily[0].productFamilyURL +
          '/' +
          element.productURL;
        performanceStore.getAllPerformanceData(element.pID).then((response) => {
          element.perfData = response;
        });
        element.resourceUri =
          '/images/products/' +
          element.productURL +
          '-hero.webp';
        this.checkImageExists(element.resourceUri).then((response) => {
          if (!response) {
            element.resourceUri =
              '/images/products/' +
              element.productURL +
              '-hero.webp';
            this.checkImageExists(element.resourceUri).then((response) => {
              if (!response) {
                element.resourceUri =
                  '/images/products/' +
                  element.productURL +
                  '-hero.webp';
              }
            });
          }
        });
        return element;
      });
      const filterItems = items.filter((element) => element.isModel === 0);
      const sortProducts = filterItems.sort(
        (a, b) => a.productSortOrder - b.productSortOrder
      );
      this.currentFamilyProductData = {
        title: title,
        products: sortProducts,
      };
    },
    checkImageExists(url) {
      return new Promise((resolve) => {
        const img = new Image();
        img.addEventListener('load', () => resolve(true));
        img.addEventListener('error', () => resolve(false));
        img.src = url;
      });
    },

  },
});
