<template>
  <div class="r-result">
    <div v-show="getFileType === 'Video'" class="r-type-video" @click="handleVideo">
      <div class="play-button">
        ▶
      </div>
      <img class="yt-thumb" :src="getYTThumb" />
      <!--            <iframe width="120" height="80" class="base-resources-video" frameborder="0"
                          allowfullscreen="allowfullscreen"
                          allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          :src="getVideoURL + '&output=embed'" />-->
    </div>
    <a class="r-link" :href="handleResource" target="_blank">
      <div class="r-type">
        <div v-show="getFileType === 'PDF'" class="r-type-pdf">PDF</div>
        <div v-show="getFileType === 'DWG'" class="r-type-pdf r-type-dwg">DWG</div>

      </div>
      <div class="r-name" v-html="getName"></div>
    </a>

    <div class="r-copy" @click="handleCopyLink">
      <div v-show="isCopied" class="r-copied">
        COPIED!
      </div>
      <img class="copy-link-icon" src="/svg/copy-link.svg">
    </div>
  </div>
</template>

<script>


import { useVideoStore } from "~/store/VideoStore";

export default {
  setup() { },
  data() {
    return {
      isCopied: false
    }
  },
  components: {
    
  },
  props: [
    "result"
  ],
  computed: {

    getName() {
      if (this.result.displayName === null && this.result.filename !== null)
        this.result.displayName = this.result.filename;
      if (this.result === null || this.result.displayName === null) {
        return;
      }

      return this.$rt(this.result.displayName).replace(/[™®©]/g, '<sup>$&</sup>')

    },

    getFileType() {
      if (!this.$props.result || (this.$props.result.filename === null && this.$props.result.videoURL === null))
        return '';
      /*console.log(this.result);*/

      if (this.$props.result.videoURL === null || this.$rt(this.$props.result.videoURL) === null) {

        if (this.$rt(this.$props.result.filename).toUpperCase().includes("PDF")) {
          return "PDF"
        }
        if (this.$rt(this.$props.result.filename).toUpperCase().includes("DWG")) {
          return "DWG"
        }
      }

      else if (this.$props.result.filename === null || this.$rt(this.$props.result.filename) === null) {
        return 'Video';
      }
    },

    getYTThumb() {

      if (this.$props.result.videoURL === null || this.$rt(this.$props.result.videoURL) === null) {
        return;
      }
      return "https://i3.ytimg.com/vi/" + this.getYoutubeVideoId(this.$rt(this.$props.result.videoURL)) + "/hqdefault.jpg";
    },

    getVideoURL() {
      if (this.$props.result.videoURL === null || this.$rt(this.$props.result.videoURL) === null) {
        return '';
      }

      return this.getYoutubeURL(this.$rt(this.$props.result.videoURL));
    },

    handleResource() {

      if ((this.$props.result.videoURL === null || this.$rt(this.$props.result.videoURL) === null) && this.result.filename !== null) {
        return '/library/' + this.$rt(this.$props.result.filename);
      } else if (this.result.videoURL !== null) {
        return this.$rt(this.$props.result.videoURL);
      } else return '';
    },

    handleCopyLink(e) {
      let resourceCUrl = '';
      if ((this.$props.result.videoURL === null || this.$rt(this.$props.result.videoURL) === null) && this.result.filename !== null) {
        resourceCUrl = 'https://nelsonirrigation.com' + this.handleResource;
      } else if (this.result.videoURL !== null) {
        resourceCUrl = this.handleResource;
      }
      navigator.clipboard.writeText(resourceCUrl);
      this.isCopied = true;
      setTimeout(this.resetCopy, 2000);
    }

  },
  methods: {

    resetCopy() {
      this.isCopied = false;
    },

    getYoutubeVideoId(url) {

      // https://youtu.be/LTHnwuUW2zE
      // https://www.youtube.com/watch?v=B-sKnDZFKLw
      // https://www.youtube.com/embed/EcHoXbNAX74

      // url = "https://www.youtube.com/embed/EcHoXbNAX74";

      let splitVideo = '';
      let videoId = '';

      if (url.indexOf('youtu.be') !== -1) {
        splitVideo = url.split('/');
        videoId = splitVideo[3];
      }

      else if (url.indexOf('watch?v') !== -1) {
        splitVideo = url.split('/');
        videoId = splitVideo[3].split("=")[1];
      }

      else if (url.indexOf('embed') !== -1) {
        splitVideo = url.split('/');
        videoId = splitVideo[4];
      }

      return videoId;

    },

    getYoutubeURL(url) {
      return 'https://youtube.com/embed/' + this.getYoutubeVideoId(url);
    },

    handleVideo() {
      useVideoStore().setVideo(this.getVideoURL);
    }

  }
}
</script>


<style scoped>
.r-result {
  display: flex;
  align-items: center;
  background-color: #F7F6F1;
  border: 1px solid #e7e7e7;
  cursor: pointer;
}

.r-result:hover {
  .r-name {
    text-decoration: underline;
  }

  .play-button {
    background-color: rgba(255, 255, 255, 1.0);
    color: #F48B41;

  }
}

.r-type-pdf {
  color: #900;
  font-weight: 900;
  width: 60px;
  height: 50px;
  margin: 8px;
  border-right: 2px solid #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.r-type-dwg {
  color: blue;
}

.r-type-video {
  display: flex;
  align-items: center;
  justify-content: center;
}

.yt-thumb {
  height: 80px;
}

.r-copy {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*align-self: flex-end;*/
}

.r-name {
  flex: 1;
  padding-left: 10px;
  color: #3B3B3B;
  line-height: 1.3;
}

.copy-link-icon {
  width: 26px;
  height: 26px;
}

.r-link {
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
}

.r-copied {
  position: absolute;
  color: white;
  background-color: #3B3B3B;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 13px;
  transform: translateX(-60px);
}

.play-button {
  width: 38px;
  height: 38px;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding-left: 3px;
  color: white;
  font-size: 10px;
  transition: .2s;
}
</style>