<template>
  <div class="r-search-results-holder" :class="isProductClass">
    <VideoOverlay />
    <ResourcesRelatedPages
    v-if="type === 'resources'"
    :link="getLink"
    :name="getProductName"
    :family="family"
    :selected="selected"
    />
    <hr class="rsr-hr">
       <div class="r-results">

    <!--   <div ref="resourcesFilterFlex" v-show="getFilterState" class="r-filter">
        <Transition>
        <ResourcesFilter
          v-show="getResourcesIntersect"
          ref="resourcesFilter"
          :groups="getGroupedResources"
          :models="getModels"
          @category-filter="handleCategoryFilter"
          @file-type-filter="handleFileTypeFilter"
          @models-filter="handleModelsFilter"/>
        </Transition>
      </div> -->

      <div ref="resourceGroups" class="r-resources">
        <div v-if="type === 'products' || type === undefined" class="related-resources">{{ resourcesLabel }}</div>
        <div v-if="type === 'resources'" class="related-resources">{{ relatedResources }}: <LocLink :to="getLink">
          <div v-html="getProductName"></div></LocLink>
        </div>
        <div v-for="(group, index) in getGroupedResources" >
          <ResourcesSearchGroup
              :group="group"
              v-show="getFilteredGroups(index)"
              :filtered-models="getModelsFiltered"
              :filtered-file-type="getFileTypeFiltered"/>
        </div>
        <div class="r-info" v-html="searchInfo" v-if="type === 'resources'">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ResourcesSearchGroup from '~/components/resources/ResourcesSearchGroup';
import ResourcesRelatedPages from "~/components/resources/ResourcesRelatedPages.vue";
import {useProductStore} from "~/store/ProductStore";
import LocLink from "~/components/base/LocLink.vue";
import VideoOverlay from "~/components/main/VideoOverlay.vue";

export default {

  setup() {},
  data () {
    return {
      resourcesData: null,
      productFamiliesData: null,
      productSpecsData: null,
      modelsData: null,
      categoryFiltered: [],
      fileTypeFiltered: [],
      modelsFiltered: [],
      family: null,
      prodFam: null,
      scrollAttached: false,
      isIntersecting: true,
    }
  },
  components: {
    VideoOverlay,
    ResourcesSearchGroup,
    ResourcesRelatedPages,
    LocLink
  },
  props: [
    "resources",
    "selected",
     "type",
     "attach"
  ],

  mounted() {

    this.resourcesData = this.$tm('resources');
    this.productFamiliesData = this.$tm('productFamilies')
    useProductStore().productFamilies = this.$tm('productFamilies');
    this.productSpecsData = this.$tm('products');
    useProductStore().productsData = this.$tm('products');
    useProductStore().terms = this.$tm('extraTerms');

  },

  computed: {

    resourcesLabel () {
      if(!this.resourcesData) {
        return
      }
      return this.$rt(this.resourcesData.label).toUpperCase();
    },

    getFilterState () {

      this.$nextTick(() => {

        if(this.attach) {

          if(!this.scrollAttached) {

            this.attach().$el.appendChild(this.$refs.resourcesFilterFlex);
            let observer = new IntersectionObserver(this.onIntersection, {
              root: null,
              threshold: .2
            })
            observer.observe( document.querySelector('.r-search-results-holder') )
            this.scrollAttached = true

            // To stop observing:
            // observer.unobserve(entry.target)

          }
        }
      })

      return true;

    },
    getResourcesIntersect () {
      return this.isIntersecting;
    },
    isProductClass () {
      return (this.type === 'products') ? "product-style" : "";
    },
    relatedResources () {
      if(!this.resourcesData) {
        return
      }
      return this.$rt(this.resourcesData.relatedResources);
    },
    searchInfo () {
      if(!this.resourcesData) {
        return
      }
      return this.$rt(this.resourcesData.searchInfo);
    },
    getGroupedResources () {

      // parse resources into groups
      if (this.resources.length === 0) {
        return;
      }

      let groupedResources = {};

      // solutions/products have array data structure - this converts to the expected object structure
      if(this.type !== "resources") {

        this.resources.forEach((resource) => {
          groupedResources[resource[0]] = resource[1]
        })
        return groupedResources;

      } else {

        groupedResources = this.$props.resources.reduce((accumulator, resource) => {
          const group = this.$rt(resource.libraryCategory);
          if (!accumulator[group]) {
            accumulator[group] = [];
          }
          accumulator[group].push(resource);
          return accumulator;
        }, {})

      }

      return groupedResources

    },
    getProductName () {

      let name = (this.selected) ? this.$rt(this.selected.productName) : "";
      return name.replace(/[™®©]/g, '<sup>$&</sup>');
    },
    getFileTypeFiltered () {
      return this.fileTypeFiltered;
    },
    getModelsFiltered () {
      return this.modelsFiltered;
    },

    getLink () {

      if(this.productFamiliesData === null || this.selected === null) {
        return;
      }

      this.family = Object.entries(this.productFamiliesData).find(([key, value]) => {
        return value.pfID === this.selected.pfID_fk;
      })

      let link = '/products/' + this.$rt(this.family[1].productFamilyURL) + '/' + this.$rt(this.selected.productURL);
      return link;
    },

    getModels () {

      if (this.productSpecsData === null || this.selected === null) {
        return null;
      }

      if(this.selected.isModel === 1) {
        return null;
      }

      useProductStore().getModelData(this.selected.pID, this.productSpecsData);
      return this.modelsData = useProductStore().currentModels;
    }

  },

  methods: {

    onIntersection(entries, opts) {

      this.isIntersecting = entries[0].isIntersecting;

    },
    getFilteredGroups (index) {

      if(this.categoryFiltered.length === 0 ) {
        return true;
      }

      if(this.type === "products") {
        this.$refs.resourceGroups.scrollIntoView({behavior: "instant"});
      }
      return this.categoryFiltered.includes(index);

    },

    handleCategoryFilter (filter) {

      if(!this.categoryFiltered.includes(filter)) {
        this.categoryFiltered.push(filter);
      } else {
        this.categoryFiltered.splice(this.categoryFiltered.indexOf(filter), 1);
      }

    },

    handleFileTypeFilter (filter) {

      if(!this.fileTypeFiltered.includes(filter)) {
        this.fileTypeFiltered.push(filter);
      } else {
        this.fileTypeFiltered.splice(this.fileTypeFiltered.indexOf(filter), 1);
      }

    },

    handleModelsFilter (filter) {

      if(!this.modelsFiltered.includes(filter)) {
        this.modelsFiltered.push(filter);
      } else {
        this.modelsFiltered.splice(this.modelsFiltered.indexOf(filter), 1);
      }

    }
  }
}
</script>

<style scoped>

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.r-search-results-holder {
  width: 100%;
  max-width: 1200px;
/*  margin: 40px;*/
}

.r-results {
  display: flex;
}

.r-filter {
  overflow-y: auto;
  flex: 1
}

.r-resources {
  flex: 2;
}

.r-info {
  padding: 40px 70px;
  border: 2px solid #e7e7e7;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 15px;
  text-align: center;
  line-height: 1.3;
}

.related-resources {
  font-weight: 900;
  margin-bottom: 20px;
  display: flex;
}

.rsr-hr {
  margin-bottom: 30px;
}

.product-style {

  margin: 0px;
}

@media only screen and (max-width: 768px) {

  .r-filter {
    display: none !important;
  }

  .related-resources {
    margin-left: 20px;
  }

}

</style>