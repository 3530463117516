
<template>
  <div class="r-search-group">
    <div class="r-search-header" @click="handleGroupToggle">
      <div class="r-search-group-category">{{ ($rt(group[0].libraryCategory)) }}</div>
      <div class="arrow-toggle" @click="handleGroupToggle">
        <div class="arrow arrow-down" v-show="!getIsOpen">▼</div>
        <div class="arrow arrow-up" v-show="getIsOpen">▲</div>
      </div>
    </div>
    <hr class="r-hr">
    <div v-show="getIsOpen" class="results-group" ref="resultsGroup">
      <ResourcesResult v-for="(result, index) in group" v-show="getFilters(result)" :result="result" />
    </div>
    <div ref="noResourcesMessage" class="no-resources" v-show="getNoResources">No resources available with selected
      filters in this category.</div>
  </div>
</template>

<script>

import ResourcesResult from '~/components/resources/ResourcesResult.vue'

export default {
  setup() {},
  updated() {

    let resourcesTotal = this.$refs.resultsGroup.children.length;
    let resourcesCnt = 0;
    Array.from(this.$refs.resultsGroup.children).forEach((item, index) => {

      if(item.style.display === 'none') {
        resourcesCnt++;
      }
    })

    this.noResources = resourcesCnt === resourcesTotal;

  },
  data() {
    return {
      noResources: false,
      isOpen: false
    }
  },
  components: {
    ResourcesResult
  },
  props: [
    "group",
    "filteredModels",
    "filteredFileType"
  ],
  mounted() {

  },
  computed: {
    getNoResources () {

     return this.noResources;
    },
    getIsOpen () {
      return this.isOpen;
    }
  },
  methods: {

    handleGroupToggle () {

      this.isOpen = !this.isOpen;

    },

    updateUI () {

   /*   nextTick(() => {

        this.noResources = true;
        Array.from(this.$refs.resultsGroup.children).forEach((item) => {
          if(item.firstElementChild.style.display !== "none") {
            this.noResources = false;
            return;
          }
        })

      })*/
    },

    getFilters (result) {

      let fileFilter = this.getFilteredFileType(result);
     // console.log(fileFilter, ' file filter');

     let modelFilter = this.getFilteredModel(result);
    // return modelFilter
    //  console.log(modelFilter, ' model filter');

     // this.updateUI();


    return (fileFilter && modelFilter)

    },

    getFilteredFileType (result) {

      if(this.$props.filteredFileType.length === 0) {
       // this.noResources = false;
        return true
      }

      let match = false;

      this.filteredFileType.some(fileType => {
        if(fileType.files.includes(result)) {
          match = true;
        }
      })

  //    let match = this.$props.filteredFileType[0].files.includes(result);
      /*value.forEach((item) => {

           if(item.videoURL === null || this.$rt(item.videoURL) === null) {

             this.fileTypes.forEach((type) => {
               if(this.$rt(item.filename).toUpperCase().includes(type.name)) {
                 type.files.push(item)
               }
             })

           } else if(item.filename === null || this.$rt(item.filename) === null) {

               this.fileTypes.find(type => type.name === "Product Video").files.push(item);

           }
         })*/

      this.updateUI();

       return match;

    },

    getFilteredModel (result) {

      if(this.$props.filteredModels.length === 0) {
      //  this.noResources = false;
        return true
      }

      let match = this.$props.filteredModels.find((resource) => {
        return resource.pID === result.cspID_fk
      });

      console.log(match, ' match');

      this.updateUI();

      return match;

    }
  }

}
</script>

<style scoped>

.r-search-group {
  padding: 15px 20px;
  background-color: white;
  border: 1px solid #e7e7e7;
  margin-top: 10px;
  margin-bottom: 10px;
}

.r-search-group-category {
  font-weight: bold;
}

.r-hr {
  width: 100%;
  border: 1px solid #e7e7e7;
  margin-bottom: 16px;
}

.r-search-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.arrow-toggle {
  cursor: pointer;
  transform: scaleY(.5);
}

</style>