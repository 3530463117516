import { defineStore } from 'pinia';
import PerfData from '~/json/perfData.json';
import ProductSolutions from '~/json/products.solutions.json'


export const usePerformanceStore = defineStore('performance', {
  state () {
    return {
      performanceData: null,
      solutionsIDs: null,
      id: null,
      recommendedCrops: [],
      recommendedSolutions: []
    };
  },
  getters: {
    recommendedFor () {
      return this.recommendedSolutions.concat(this.recommendedCrops);
    },
  },
  actions: {
    getPerformanceData (pID) {
      this.performanceData = PerfData.find((entry) => entry.pID_fk == pID);
      return this.performanceData;
    },
    async getAllPerformanceData (pID) {
      return PerfData.find((entry) => entry.pID_fk == pID);
    },
    getSolutionsData (pID) {
      this.solutionsIDs = ProductSolutions
        .filter((entry) => entry.pID_fk == pID)
        .map((each) => {
          return each.solID_fk;
        });
    },
    setProductCrops (productID, tmData) {
      this.recommendedCrops = tmData
        .filter((each) => {
          return each.pID_fk == productID;
        })
        .map((each) => {
          return {
            name: each.crop,
            url: '/crops/' + each.cropURL.body.static,
          }
        });
    },
    plateCrops (productID, tmData) {
      const items = tmData
        .filter((each) => {
          return each.pID_fk == productID;
        })
        .map((each) => {
          return {
            name: each.crop,
            url: '/crops/' + each.cropURL.body.static,
          };
        });
      return items;
    },
    setProductSolutions (tmData) {
      this.recommendedSolutions = tmData
        .filter((each) => {
          return this.solutionsIDs.includes(each.solID_fk);
        })
        .map((each) => {
          return {
            name: each.solution,
            url:
              '/solutions/' +
              each.solutionGroupURL.body.static +
              '/' +
              each.solutionURL.body.static,
          }
        });
    },
  },
});
