<template>
  <div class="r-related-pages-holder">
    <div class="r-related-pages-title">{{ relatedPages }}</div>
    <div class="r-related-pages">
      <LocLink class="r-loc-link" :to="getFamilyLink">
        <div class="r-img-holder">
          <div class="r-img">
            <img :src="getFamilyImage" />
          </div>
          <div class="related-page-name" v-html="getFamilyName"></div>
        </div>
      </LocLink>
      <LocLink class="r-loc-link" :to="link">
        <div class="r-img-holder">
          <div class="r-img">
            <img :src="getProductImage" />
          </div>
          <div class="related-page-name" v-html="name.replace(/[™®©]/g, '<sup>$&</sup>')"></div>
        </div>
      </LocLink>
    </div>
  </div>
</template>

<script>

import LocLink from "~/components/base/LocLink.vue";

export default {
  setup() {
  },
  components: {LocLink},
  data() {
    return {
      resourcesData: null,
      productFamiliesData: null
    }
  },
  props: [
    "link",
    "name",
    "family",
    "selected"
  ],
  mounted() {
    this.resourcesData = this.$tm('resources');
    this.productFamiliesData = this.$tm('productFamilies');
  },
  computed: {
    relatedPages () {
      if(!this.resourcesData) {
        return
      }
      return this.$rt(this.resourcesData.relatedPages);
    },
    getFamilyLink () {

      if(this.$props.family === null) {
        return;
      }
      return "/products/" + this.$rt(this.$props.family[1].productFamilyURL);
    },
    getFamilyName () {

      if(this.$props.family === null) {
        return;
      }
      return this.$rt(this.$props.family[1].productFamily).replace(/[™®©]/g, '<sup>$&</sup>');
    },
    getFamilyImage () {

      if(this.$props.family === null) {
        return;
      }
      return "/images/productfamilies/" + this.$rt(this.$props.family[1].productFamilyURL) + "-hero-1.webp"
    },
    getProductImage () {

      if(this.$props.selected === null) {
        return;
      }
      return "/images/products/" + this.$rt(this.$props.selected.productURL) + "-hero.webp";
    }

  },
  methods : {}


}
</script>

<style scoped>
.r-related-pages-holder {
  display: flex;
  flex-direction: column;
}

.r-related-pages-title {
  font-weight: 900;
  font-size: 15x;
  margin-top: 20px;
  margin-bottom: 20px;
}

.r-img {
  width: 200px;
  height: 130px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

}

.r-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.r-img-holder {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.r-related-pages {
  display: flex;
  gap: 20px;
  height: 100%;
}

.related-page-name {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 14px;
  color: #0089D0;
}

.r-loc-link {
  text-decoration: none;
}

</style>